@import '../../styles/index';

.base {
  border-radius: 6px;
  font-size: $font-size-16;

  @include media-from(sm) {
    margin-top: auto;
  }
}

@mixin product-overview-button-theme($base-color, $color-10, $shadow) {
  color: $base-color;
  background: $color-10;

  &:focus {
    box-shadow: $shadow;
  }

  &.active {
    color: white;
    background: $base-color;
  }
}

.yellow {
  @include product-overview-button-theme(
    $color-yellow--100,
    $color-yellow--10,
    $shadow-product-overview-button-yellow
  );
}

.green {
  @include product-overview-button-theme(
    $color-green--100,
    $color-green--10,
    $shadow-product-overview-button-green
  );
}

.turquoise {
  @include product-overview-button-theme(
    $color-turquoise--100,
    $color-turquoise--10,
    $shadow-product-overview-button-turquoise
  );
}

.blue {
  @include product-overview-button-theme(
    $color-blue--100,
    $color-blue--10,
    $shadow-product-overview-button-blue
  );
}

.orange {
  @include product-overview-button-theme(
    $color-orange--100,
    $color-orange--10,
    $shadow-product-overview-button-orange
  );
}

.purple {
  @include product-overview-button-theme(
    $color-violet--100,
    $color-violet--10,
    $shadow-product-overview-button-violet
  );
}

.surrogate {
  @include product-overview-button-theme(
    $color-surrogate--100,
    $color-surrogate--10,
    $shadow-product-overview-button-violet
  );
}
