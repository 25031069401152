@import '../../styles/index';

.base {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 1rem;
  background-color: $color-white--100;
  border: 2px solid $color-surrogate--100;
}
