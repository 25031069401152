@import '../../../styles/index';

.base {
  font-size: $font-size-16;
  font-weight: $font-weight-medium;
  color: $color-black--100;

  @include media-from(sm) {
    font-size: $font-size-18;
    line-height: 1.6;
  }
}
