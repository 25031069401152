@import '../../styles/index';

.base {
  margin: 0;
  max-width: 100%;
  position: relative;

  img {
    object-fit: contain;
  }

  > figcaption {
    color: $color-black--80;
    font-size: $font-size-14;
    line-height: 1.5;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0.75rem;
    width: 100%;

    @include media-from(xs) {
      font-size: $font-size-16;
    }
  }
}

.shadow {
  box-shadow: 0 0 27px rgba(0, 0, 0, 0.12);
}

.white-round-border {
  border: 0.75rem solid $color-white--100;
  border-radius: 1rem;
  box-shadow:
    0px 0px 2px 0px rgba(0, 0, 0, 0.08),
    0px 16px 48px 0px rgba(0, 0, 0, 0.08);
}

.clickable {
  width: 100%;
}

.left {
  align-self: flex-start;
  text-align: left;
}

.center {
  align-self: center;
  text-align: center;
}

.right {
  align-self: flex-end;
}
