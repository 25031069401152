@import '../../styles/index';

.base-no-box {
  width: 350px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.base-no-box,
.base {
  width: 100%;
  height: auto;
  align-self: stretch;

  @include media-from(sm) {
    &:not(.with-badge) {
      margin-top: 2.5rem;
    }
  }
}

.hover {
  transition: background-color $duration-default,
    transform $duration-default ease-in-out,
    box-shadow $duration-default ease-in-out;

  .box {
    transition: box-shadow $duration-default ease-in-out;
  }

  &:hover {
    transform: translateY(-2px);
    z-index: 1; // the hovered element have to be on top of the other elements

    .box {
      box-shadow: $shadow-button-hover;
    }
  }
}

.with-badge {
  margin-top: 1.25rem;

  @include media-from(sm) {
    margin-top: 0;
  }
}

.box {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 1.5rem !important; // @todo: must be refactored after react static

  @include media-from(sm) {
    padding: 1.25rem !important; // @todo: must be refactored after react static
  }
}

.headline-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 1.25rem;

  @include media-from(sm) {
    min-height: 4.6rem;
  }
}

.headline-first {
  color: $color-black--100;
  font-size: $font-size-24;
  font-weight: $font-weight-bold;
  text-align: center;
}

.headline-second {
  color: $color-black--60;
  font-size: $font-size-18;
  text-align: center;
}

.headline-second-disabled {
  display: none;
}

.image-wrapper {
  width: 100%;
  margin-top: 1.625rem;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: center;

  & > div,
  picture,
  image {
    margin: 0 auto;
  }
}

.image-box {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.image-comment {
  height: 1.25rem;
  width: 216px;
  color: $color-black--60;
  font-size: 1rem;
  line-height: 1.25rem;
}

.badge {
  width: 100%;
  max-width: 320px;
  text-align: center;
  top: 0;
  transform: translateY(-50%);
  font-size: 0.9rem;
  padding: 0.625rem 1rem;
  position: absolute;
  align-self: center;

  @include media-from(sm) {
    width: unset;
    max-width: unset;
    top: 0.5rem;
    padding: 0.625rem 1.75rem;
  }
}

.bottom-group {
  margin-top: auto;
}

.price-wrapper {
  display: inline;
}

.price {
  text-align: center;
  font-weight: $font-weight-medium;
  font-size: 2rem;
}

.price-gradient {
  color: $color-violet--100;
  background: -webkit-linear-gradient(
    180deg,
    #{$color-red--100} 10%,
    #{$color-violet--100} 90%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.price-comment {
  color: $color-black--60;
  font-size: $font-size-16;
  line-height: 1;
  text-align: center;
  margin-bottom: 1.5rem;
  position: relative;
}

.price-comment-clickable {
  cursor: pointer;
  text-decoration: underline;
}

.price-extension {
  background-color: $color-black--10;
  margin-bottom: 2.25rem;
  padding: 1rem;

  @include media-from(xs) {
    padding: 1rem 1.25rem;
  }
}

.price-extension-infos {
  margin: 0.5rem 0 1.5rem 0;
  padding: 0;
  list-style-type: none;
}

.price-extension-info {
  font-size: $font-size-18;
  line-height: 1.325;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 0.825rem;

  @include media-from(xs) {
    flex-direction: row;
  }

  @include media-from(sm) {
    flex-direction: column;
  }

  @include media-from(md) {
    flex-direction: row;
  }

  strong {
    font-weight: $font-weight-medium;
  }
}

.price-extension-footnote {
  font-size: $font-size-16;
  display: block;
  line-height: 1.2;
}

.button {
  width: 100%;

  @include media-from(md) {
    display: flex;
  }
}

.buttonMarginDefault {
  margin: 0 auto 1.5rem !important;
  @include media-from(md) {
    margin: 0 auto 1rem !important;
  }
}

.buttonMarginLarge {
  margin: 0.5rem auto 1.5rem !important;
  @include media-from(md) {
    margin: 0.5rem auto 1rem !important;
  }
}

.detail-button {
  padding: 4px 0;
}

.icon {
  height: 1.25rem;
  width: 1.25rem;
  margin-right: 0.5rem;
  position: relative;
  top: 0.1875rem;
}

.badge-base {
  position: absolute;
  left: 0;
  top: -2.5rem;
  width: 100%;
  text-align: center;
  padding: 0.625rem;
  line-height: 1.25;
  font-size: $font-size-16;
  font-weight: $font-weight-bold;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  color: $color-white--100;

  &.yellow {
    background: $color-yellow--100;
  }
  &.green {
    background: $color-green--100;
  }
  &.turquoise {
    background: $color-turquoise--100;
  }
  &.blue {
    background: $color-blue--100;
  }
  &.orange {
    background: $color-orange--100;
  }
  &.purple {
    background: $color-violet--100;
  }
  &.surrogate {
    background: $color-surrogate--100;
  }
}

.alternative {
  &.base {
    @include media-from(sm) {
      max-width: 290px;
    }
  }

  .headline-second {
    font-size: $font-size-14;
    line-height: 1.7;
    color: $color-black--100;
    font-weight: $font-weight-light;
  }

  .headline-first {
    font-size: $font-size-22;
    line-height: 1.1;
  }

  .icon-big {
    align-self: center;
    margin-bottom: 1rem;
    max-width: 88px;
    width: 100%;
  }

  .price {
    font-size: $font-size-30;
    line-height: 1.25;
  }

  .price-comment {
    font-size: $font-size-14;
    line-height: 1.3;
    margin-bottom: 0.75rem;
    color: $color-black--100;
    font-weight: $font-weight-light;
  }

  .detail-link-wrapper {
    display: flex;
    justify-content: center;
  }

  .detail-link {
    border: none;
    background-color: transparent;
    color: $color-surrogate--100;
    font-size: $font-size-14;
    line-height: 1.3;
    font-weight: $font-weight-medium;
    cursor: pointer;
    margin-bottom: 0.25rem;
    border-bottom: 2px solid $color-surrogate--100;
    padding-left: 0;
    padding-right: 0;

    &:focus {
      outline: none;
    }
  }

  .details-list {
    font-size: 0.875rem;
    margin-bottom: 1.25rem;
    ul {
      li {
        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    &.yellow {
      ul {
        li {
          &::before {
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='22px' height='20px' viewBox='0 0 22 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 57 (83077) - https://sketch.com --%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='CARD/Active' transform='translate(-22.000000, -20.000000)' fill='%23fc0' fill-rule='nonzero'%3E%3Cpath d='M29.4831834,40 C29.0484873,40 28.6286385,39.8285671 28.3239908,39.5221973 L22.4631129,33.640471 C21.8370775,33.0112599 21.8463511,32.0027572 22.4872626,31.3881593 C23.1281832,30.7735614 24.1554537,30.7844863 24.7814891,31.4136919 L29.3216721,35.9693919 L41.0809975,20.6352581 C41.6197189,19.9331206 42.6358612,19.790863 43.3529199,20.3197515 C44.068124,20.8486306 44.213029,21.8480292 43.6742981,22.5501667 L30.7802716,39.3652367 C30.4960447,39.7354568 30.0595035,39.9670889 29.5876467,39.9962687 C29.5523507,39.9980928 29.5170537,40 29.4836166,40 L29.4831834,40 Z' id='Path-Copy'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
          }
        }
      }
    }
    &.green {
      ul {
        li {
          &::before {
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='22px' height='20px' viewBox='0 0 22 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 57 (83077) - https://sketch.com --%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='CARD/Active' transform='translate(-22.000000, -20.000000)' fill='%2364b32c' fill-rule='nonzero'%3E%3Cpath d='M29.4831834,40 C29.0484873,40 28.6286385,39.8285671 28.3239908,39.5221973 L22.4631129,33.640471 C21.8370775,33.0112599 21.8463511,32.0027572 22.4872626,31.3881593 C23.1281832,30.7735614 24.1554537,30.7844863 24.7814891,31.4136919 L29.3216721,35.9693919 L41.0809975,20.6352581 C41.6197189,19.9331206 42.6358612,19.790863 43.3529199,20.3197515 C44.068124,20.8486306 44.213029,21.8480292 43.6742981,22.5501667 L30.7802716,39.3652367 C30.4960447,39.7354568 30.0595035,39.9670889 29.5876467,39.9962687 C29.5523507,39.9980928 29.5170537,40 29.4836166,40 L29.4831834,40 Z' id='Path-Copy'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
          }
        }
      }
    }
    &.turquoise {
      ul {
        li {
          &::before {
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='22px' height='20px' viewBox='0 0 22 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 57 (83077) - https://sketch.com --%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='CARD/Active' transform='translate(-22.000000, -20.000000)' fill='%23009aa3' fill-rule='nonzero'%3E%3Cpath d='M29.4831834,40 C29.0484873,40 28.6286385,39.8285671 28.3239908,39.5221973 L22.4631129,33.640471 C21.8370775,33.0112599 21.8463511,32.0027572 22.4872626,31.3881593 C23.1281832,30.7735614 24.1554537,30.7844863 24.7814891,31.4136919 L29.3216721,35.9693919 L41.0809975,20.6352581 C41.6197189,19.9331206 42.6358612,19.790863 43.3529199,20.3197515 C44.068124,20.8486306 44.213029,21.8480292 43.6742981,22.5501667 L30.7802716,39.3652367 C30.4960447,39.7354568 30.0595035,39.9670889 29.5876467,39.9962687 C29.5523507,39.9980928 29.5170537,40 29.4836166,40 L29.4831834,40 Z' id='Path-Copy'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
          }
        }
      }
    }
    &.blue {
      ul {
        li {
          &::before {
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='22px' height='20px' viewBox='0 0 22 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 57 (83077) - https://sketch.com --%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='CARD/Active' transform='translate(-22.000000, -20.000000)' fill='%2314459c' fill-rule='nonzero'%3E%3Cpath d='M29.4831834,40 C29.0484873,40 28.6286385,39.8285671 28.3239908,39.5221973 L22.4631129,33.640471 C21.8370775,33.0112599 21.8463511,32.0027572 22.4872626,31.3881593 C23.1281832,30.7735614 24.1554537,30.7844863 24.7814891,31.4136919 L29.3216721,35.9693919 L41.0809975,20.6352581 C41.6197189,19.9331206 42.6358612,19.790863 43.3529199,20.3197515 C44.068124,20.8486306 44.213029,21.8480292 43.6742981,22.5501667 L30.7802716,39.3652367 C30.4960447,39.7354568 30.0595035,39.9670889 29.5876467,39.9962687 C29.5523507,39.9980928 29.5170537,40 29.4836166,40 L29.4831834,40 Z' id='Path-Copy'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
          }
        }
      }
    }
    &.orange {
      ul {
        li {
          &::before {
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='22px' height='20px' viewBox='0 0 22 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 57 (83077) - https://sketch.com --%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='CARD/Active' transform='translate(-22.000000, -20.000000)' fill='%23ed7101' fill-rule='nonzero'%3E%3Cpath d='M29.4831834,40 C29.0484873,40 28.6286385,39.8285671 28.3239908,39.5221973 L22.4631129,33.640471 C21.8370775,33.0112599 21.8463511,32.0027572 22.4872626,31.3881593 C23.1281832,30.7735614 24.1554537,30.7844863 24.7814891,31.4136919 L29.3216721,35.9693919 L41.0809975,20.6352581 C41.6197189,19.9331206 42.6358612,19.790863 43.3529199,20.3197515 C44.068124,20.8486306 44.213029,21.8480292 43.6742981,22.5501667 L30.7802716,39.3652367 C30.4960447,39.7354568 30.0595035,39.9670889 29.5876467,39.9962687 C29.5523507,39.9980928 29.5170537,40 29.4836166,40 L29.4831834,40 Z' id='Path-Copy'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
          }
        }
      }
    }
    &.purple {
      ul {
        li {
          &::before {
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='22px' height='20px' viewBox='0 0 22 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 57 (83077) - https://sketch.com --%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='CARD/Active' transform='translate(-22.000000, -20.000000)' fill='%23909' fill-rule='nonzero'%3E%3Cpath d='M29.4831834,40 C29.0484873,40 28.6286385,39.8285671 28.3239908,39.5221973 L22.4631129,33.640471 C21.8370775,33.0112599 21.8463511,32.0027572 22.4872626,31.3881593 C23.1281832,30.7735614 24.1554537,30.7844863 24.7814891,31.4136919 L29.3216721,35.9693919 L41.0809975,20.6352581 C41.6197189,19.9331206 42.6358612,19.790863 43.3529199,20.3197515 C44.068124,20.8486306 44.213029,21.8480292 43.6742981,22.5501667 L30.7802716,39.3652367 C30.4960447,39.7354568 30.0595035,39.9670889 29.5876467,39.9962687 C29.5523507,39.9980928 29.5170537,40 29.4836166,40 L29.4831834,40 Z' id='Path-Copy'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
          }
        }
      }
    }
    &.surrogate {
      ul {
        li {
          &::before {
            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='22px' height='20px' viewBox='0 0 22 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 57 (83077) - https://sketch.com --%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='CARD/Active' transform='translate(-22.000000, -20.000000)' fill='%23c7125c' fill-rule='nonzero'%3E%3Cpath d='M29.4831834,40 C29.0484873,40 28.6286385,39.8285671 28.3239908,39.5221973 L22.4631129,33.640471 C21.8370775,33.0112599 21.8463511,32.0027572 22.4872626,31.3881593 C23.1281832,30.7735614 24.1554537,30.7844863 24.7814891,31.4136919 L29.3216721,35.9693919 L41.0809975,20.6352581 C41.6197189,19.9331206 42.6358612,19.790863 43.3529199,20.3197515 C44.068124,20.8486306 44.213029,21.8480292 43.6742981,22.5501667 L30.7802716,39.3652367 C30.4960447,39.7354568 30.0595035,39.9670889 29.5876467,39.9962687 C29.5523507,39.9980928 29.5170537,40 29.4836166,40 L29.4831834,40 Z' id='Path-Copy'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
          }
        }
      }
    }
  }

  .price-extension {
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    margin-bottom: 0;
  }

  .price-extension-infos {
    margin-bottom: 1rem;
  }

  .price-extension-info {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 0.5rem;
    flex-direction: row;
  }

  .price-extension-footnote {
    color: $color-black--100;
    font-size: $font-size-14;
    font-weight: $font-weight-light;
  }

  &.with-badge {
    padding-top: 2.5rem;
    margin-top: 0;

    .box {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      padding-top: 1.25rem !important; // @todo: must be refactored after react static
    }
  }

  &.base-no-box {
    .price-comment {
      margin-bottom: 0.5rem;
    }

    .details-list {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      ul {
        font-size: 1rem;
        line-height: 1.25rem;
      }
    }
  }
}

.savings {
  border: solid 2px;
  border-radius: 6px;
  padding: 0.75rem;
  text-align: center;
  position: relative;

  &.yellow {
    border-color: rgba($color-yellow--100, 0.2);
  }
  &.green {
    border-color: rgba($color-green--100, 0.2);
  }
  &.turquoise {
    border-color: rgba($color-turquoise--100, 0.2);
  }
  &.blue {
    border-color: rgba($color-blue--100, 0.2);
  }
  &.orange {
    border-color: rgba($color-orange--100, 0.2);
  }
  &.purple {
    border-color: rgba($color-violet--100, 0.2);
  }
  &.surrogate {
    border-color: rgba($color-surrogate--100, 0.2);
  }
}

.savings-info {
  position: absolute;
  top: -10px;
  right: 8px;
  background-color: $color-white--100;

  .yellow {
    color: $color-yellow--100;
  }
  .green {
    color: $color-green--100;
  }
  .turquoise {
    color: $color-turquoise--100;
  }
  .blue {
    color: $color-blue--100;
  }
  .orange {
    color: $color-orange--100;
  }
  .purple {
    color: $color-violet--100;
  }
  .surrogate {
    color: $color-surrogate--100;
  }
}

.savings-info-link {
  flex-grow: 1;
  margin: 1.25rem 0 1.25rem 0;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  // fix safari bug
  &:active {
    color: $color-black--100;
  }

  @include media-from(sm) {
    flex-grow: 0;
    margin: 1.25rem auto 1.25rem auto;
  }
}

.savings-headline {
  font-size: $font-size-18;
  line-height: 1.1;
  margin-bottom: 4px;
  font-weight: $font-weight-medium;
}

.savings-footnote {
  color: $color-black--100;
  font-size: $font-size-14;
  font-weight: $font-weight-light;
  line-height: 1.5;
}
