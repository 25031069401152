@import '../../styles/index';

@function repeatChar($char, $times) {
  $content: '';
  @for $i from 1 through $times {
    $content: $content + $char;
  }
  @return $content;
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
  margin: 0;
  font-size: $font-size-16;
  line-height: 1.325;

  @include media-from(xs) {
    font-size: $font-size-16;
  }

  & + & {
    padding-top: 1rem;
    margin-top: 1rem;
  }
}

.title {
  font-weight: $font-weight-medium;
  margin-bottom: 0.75rem;
}

.row {
  align-items: end;
  display: grid;
  gap: 0 0.5rem;
  grid-template-areas: 'left right';
  grid-template-columns: auto max-content;
  list-style: none;
  margin: 0;
  padding: 0;

  &:not(:last-of-type) {
    margin-bottom: 0.5rem;
  }

  & > * {
    &:first-child {
      grid-area: left;
      overflow: hidden;
      position: relative;

      &::after {
        content: repeatChar(' .', 80);
        padding-left: 0.25ch;
        position: absolute;
        text-align: right;
        color: var(--accent--40, $color-black--40);
      }
    }

    &:last-child {
      grid-area: right;
      font-weight: $font-weight-medium;
    }
  }
}
