@import '../../styles/index';

.base {
  position: relative;
  width: 100%;
  padding: 1rem;

  background-color: $color-white--100;
  border-radius: $border-radius-default;
  line-height: 1.375;

  --shadow-offset: 24px;
  --shadow-blur: 48px;
  --shadow-alpha: 0.12;
  --neg-shadow-offset: calc(var(--shadow-offset) * -1);
  /* we can't use "filter: drop-shadow..." because of bugs in Safari */
  box-shadow: 0px var(--shadow-offset) var(--shadow-blur)
      rgba($color-black--100, var(--shadow-alpha)),
    0px 0px 2px rgba($color-black--100, var(--shadow-alpha));

  @include media-to(sm) {
    font-size: 1rem; // ugly legacy code...
  }

  @include media-from(sm) {
    padding: 1rem 1.25rem;
  }
}

.reducedShadow {
  --shadow-offset: 8px;
  --shadow-blur: 24px;
  --shadow-alpha: 0.08;
}

.arrow:before {
  content: '';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;

  border-style: solid;
  border-color: $color-white--100 transparent transparent $color-white--100;
  border-width: 0.5rem;
  border-top-left-radius: $border-radius-default;

  /* drop-shadow workaround to prevent bug in Safari */
  clip-path: polygon(-1000% -1000%, -1000% 1100%, 1100% -1000%);

  transform: translate(-50%, calc(50% + 0.1px)) rotate(45deg);

  @include media-from(sm) {
    border-width: 0.5625rem;
  }
}

@mixin arrow-top {
  bottom: 100%;
  box-shadow: var(--shadow-offset) var(--shadow-offset) var(--shadow-blur)
      rgba($color-black--100, var(--shadow-alpha)),
    0px 0px 2px rgba($color-black--100, var(--shadow-alpha));
}

@mixin arrow-left {
  left: 0%;
  transform: translate(calc(-50% + 0.1px), 50%) rotate(315deg);
  box-shadow: var(--neg-shadow-offset) var(--shadow-offset) var(--shadow-blur)
      rgba($color-black--100, var(--shadow-alpha)),
    0px 0px 2px rgba($color-black--100, var(--shadow-alpha));
}

@mixin arrow-right {
  left: 100%;
  transform: translate(calc(-50% - 0.1px), 50%) rotate(135deg);
  box-shadow: var(--shadow-offset) var(--neg-shadow-offset) var(--shadow-blur)
      rgba($color-black--100, var(--shadow-alpha)),
    0px 0px 2px rgba($color-black--100, var(--shadow-alpha));
}

@mixin arrow-bottom {
  bottom: 0%;
  transform: translate(-50%, calc(50% - 0.1px)) rotate(225deg);
  box-shadow: var(--neg-shadow-offset) var(--neg-shadow-offset)
      var(--shadow-blur) rgba($color-black--100, var(--shadow-alpha)),
    0px 0px 2px rgba($color-black--100, var(--shadow-alpha));
}

.TopCenter:before {
  left: 50%;
  @include arrow-top;
}

.TopLeft:before {
  left: 25%;
  @include arrow-top;
}

.TopRight:before {
  left: 75%;
  @include arrow-top;
}

.Left:before {
  bottom: 50%;
  @include arrow-left;
}

.Right:before {
  bottom: 50%;
  @include arrow-right;
}

.BottomCenter:before {
  left: 50%;
  @include arrow-bottom;
}

.BottomLeft:before {
  left: 25%;
  @include arrow-bottom;
}

.BottomRight:before {
  left: 75%;
  @include arrow-bottom;
}
